import * as React from 'react';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import Popup from 'components/Map/Layers/Popup';
import { LinkNode } from '../LinkNode';
import { NodeFilter, SegmentStats } from 'model/SelectedLink';
import { SelectedLinkContextMenuItem } from './SelectedLinkContextMenuItem';
import { ReverseGeocodeApi } from 'api/ReverseGeocodeApi';
import { BoldText } from 'components/UI/FormUI';

export type Direction = 'in' | 'out';

const StyledPopup = styled(Popup)`
  & > div {
    padding: 0 0 6px 0;
  }
  & .mapboxgl-popup-tip {
    display: none;
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  max-width: 500px;
  min-width: 200px;
`;

const Title = styled(BoldText)`
  padding: 0 15px;
  height: 28px;
  display: block;
  font-weight: bold;
  align-content: center;
`;

interface Props {
  lngLat?: mapboxgl.LngLat;
  treeIn: LinkNode;
  treeOut: LinkNode;
  selectedSegmentsStatsIn: SegmentStats[];
  selectedSegmentsStatsOut: SegmentStats[];
  nodeFilterIn: NodeFilter;
  nodeFilterOut: NodeFilter;
  isSelectedLinkRegionAnalysis: boolean;
  onDirectionClick: (direction: Direction) => void;
  onDirectionEnter: (direction: Direction, node: LinkNode) => void;
  onDirectionLeave: (direction: Direction) => void;
}

const SelectedLinkContextMenu: React.FC<Props> = ({
  lngLat,
  treeIn,
  treeOut,
  selectedSegmentsStatsIn,
  selectedSegmentsStatsOut,
  nodeFilterIn,
  nodeFilterOut,
  isSelectedLinkRegionAnalysis,
  onDirectionClick,
  onDirectionEnter,
  onDirectionLeave,
}) => {
  const [segmentName, setSegmentName] = React.useState<string | null>(null);

  React.useEffect(() => {
    const abortController = new AbortController();

    ReverseGeocodeApi.getRoadName(
      [lngLat.lng, lngLat.lat],
      abortController.signal,
    )
      .then(setSegmentName)
      .catch((err) => {
        if (!abortController.signal.aborted) {
          console.error(err);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [lngLat.lng.toFixed(6), lngLat.lat.toFixed(6)]);

  return (
    <StyledPopup
      position={lngLat}
      anchor="top-left"
      closeButton={false}
      maxWidth={500}
    >
      <Title fontSize="12">Choose segment</Title>
      <List>
        {selectedSegmentsStatsIn
          .filter((el) => el.paths.length > 0)
          .map((el) => (
            <SelectedLinkContextMenuItem
              key={el.index}
              segmentName={segmentName || 'Incoming'}
              tree={treeIn}
              selectedSegmentStats={el}
              nodeFilter={nodeFilterIn}
              isSelectedLinkRegionAnalysis={isSelectedLinkRegionAnalysis}
              onClick={() => onDirectionClick('in')}
              onMouseEnter={() => onDirectionEnter('in', el.paths[0].node)}
              onMouseLeave={() => onDirectionLeave('in')}
            />
          ))}
        {selectedSegmentsStatsOut
          .filter((el) => el.paths.length > 0)
          .map((el) => (
            <SelectedLinkContextMenuItem
              key={el.index}
              segmentName={segmentName || 'Outgoing'}
              tree={treeOut}
              selectedSegmentStats={el}
              nodeFilter={nodeFilterOut}
              isSelectedLinkRegionAnalysis={isSelectedLinkRegionAnalysis}
              onClick={() => onDirectionClick('out')}
              onMouseEnter={() => onDirectionEnter('out', el.paths[0].node)}
              onMouseLeave={() => onDirectionLeave('out')}
            />
          ))}
      </List>
    </StyledPopup>
  );
};

// Requires memo, otherwise popup might be remounted all the time, triggering unlimited calls to reverse geocoder in SelectedLinkContextMenuItem
export default React.memo(SelectedLinkContextMenu);
