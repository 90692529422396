import { useEffect } from 'react';
import {
  EventData,
  Map,
  MapboxGeoJSONFeature,
  MapEventType,
  PointLike,
} from 'mapbox-gl';
import bbox from '@turf/bbox';
import { Feature, featureCollection, Geometry } from '@turf/helpers';
import {
  LineString,
  centroid,
  lineString,
  point,
  pointToLineDistance,
  distance as turfDistance,
} from '@turf/turf';
import { useMap } from 'legoland-shared';
import { RegionDto, RegionDtoProps } from 'model/RegionDto';

function panToFeatures(
  map: Map,
  features: Feature[] | undefined,
  animate: boolean,
) {
  if (!features || features.length === 0) return;
  const regionsCollection = featureCollection(features);
  const regionsBbox = bbox(regionsCollection);
  setTimeout(
    () =>
      map.fitBounds(
        [regionsBbox[0], regionsBbox[1], regionsBbox[2], regionsBbox[3]],
        { padding: 100, animate },
      ),
    0,
  );
}

export const centerOnRegions = (
  map: Map,
  dtoRegions: Feature[] | undefined,
  animate = false,
) => panToFeatures(map, dtoRegions, animate);

export const queryFeatures = (
  e: MapEventType['click'] & EventData,
  layers: string[],
  distance = 10,
) => {
  const map = e.target;
  if (!map.getLayer(layers[0])) {
    return [];
  }
  const bbox: [PointLike, PointLike] = [
    [e.point.x - distance, e.point.y - distance],
    [e.point.x + distance, e.point.y + distance],
  ];
  let features = map.queryRenderedFeatures(bbox, {
    layers,
  });

  const mousePoint = point([e.lngLat.lng, e.lngLat.lat]);

  const featuresSorted = features
    .map((it: MapboxGeoJSONFeature) => {
      return {
        distance:
          it.geometry.type == 'Point'
            ? turfDistance(mousePoint, it.geometry as any)
            : pointToLineDistance(mousePoint, it.geometry as any),
        feature: it,
      };
    })
    .sort((a, b) => a.distance - b.distance);

  return featuresSorted;
};

interface CenterOnRegionsProps {
  regions?: RegionDto[];
}

export const CenterOnRegions: React.FC<CenterOnRegionsProps> = ({
  regions,
}) => {
  const { map } = useMap();

  useEffect(() => {
    centerOnRegions(map, regions);
  }, [map]);

  return null;
};

export function validateLatLng(lat: number, lng: number) {
  return lat && lng && Math.abs(lat) <= 90 && Math.abs(lng) <= 180;
}

export const lineStringToReverseGeocodePoint = (
  lineString: Feature<LineString>,
) => centroid(lineString).geometry.coordinates as [number, number];

export function toTurfFeature<T>(
  feature: MapboxGeoJSONFeature,
): Feature<LineString, T> {
  const geometry = (feature.geometry as unknown) as any;

  return lineString<T>(geometry.coordinates, {
    ...(feature.properties as T),
  });
}

export const isLayerLineString = ['==', ['geometry-type'], 'LineString'];
