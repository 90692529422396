import React from 'react';
import { useMap } from 'legoland-shared';

export function withMap<T extends React.ComponentType<P> | typeof React.Component<P>, P>(
  Component: T | React.MemoExoticComponent<T>,
) {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { map, mapStyleMode } = useMap();
    if (!map) return null;
    return <Component map={map} mapStyleMode={mapStyleMode} {...props} />;
  };
}
