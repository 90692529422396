import {
  Feature,
  featureCollection,
  LineString,
  lineString,
} from '@turf/helpers';
import { useMemo } from 'react';
import { Angle } from './Angle';
import {
  SelectedLinkUnit,
  SelectedSegment,
  StyledSegmentProperties,
} from 'model/SelectedLink';
import { LinkNode, TreeType } from './LinkNode';
import { log } from 'logic/math';

export function makeSegmentStyle(
  trips: number,
  maxTrips: number,
  getColor: (v: number) => string,
  unit: SelectedLinkUnit,
): StyledSegmentProperties {
  const minLineWidth = 3;
  const maxLineWidth = 14;

  const minTextSize = 13;
  const maxTextSize = 18;

  const percent = (trips / maxTrips) * 100;
  const logPercent = log(percent);

  const width =
    minLineWidth + (logPercent * (maxLineWidth - minLineWidth)) / 100;

  const color = getColor(Math.round(percent));

  const styles = {
    'text-size': minTextSize + (logPercent / 100) * (maxTextSize - minTextSize),
    'text-color': '#FFF',
    'text-halo-color': color,
    'line-width': width,
    'line-color': color,
    text:
      unit === SelectedLinkUnit.Percents
        ? `${((trips / maxTrips) * 100).toFixed(0)}%`
        : trips.toLocaleString(),
    percent,
    trips,
  };

  if (percent < 1) {
    (styles as any).text = undefined;
  }

  return styles;
}

interface UnstyledSegmentProperties {
  hash: string;
}

/**
 * Renders Tree to geojson
 */
export const useTreeRenderer = ({
  root,
  getColor,
  unit,
  shouldShowNode,
  selectedSegments,
  hover,
}: {
  root: LinkNode;
  getColor: (v: number) => string;
  unit: SelectedLinkUnit;
  shouldShowNode: (node: LinkNode, ignoreHighligted?: boolean) => boolean;
  selectedSegments: SelectedSegment[];
  hover: { nodes: Set<LinkNode> };
}) => {
  // Styled active segments
  const geometry = useMemo(() => {
    // No results
    if (root.trips === 0) {
      return {
        selectedLink: lineString(root.coordinates, {
          text: 'No Data',
          'text-size': 13,
          'line-width': 8,
        }),
        links: [],
        icons: [],
        selectedSegmentsGeometry: [],
      };
    }

    const selected = selectedSegments.map((it) => it.segmentHash);

    // Trips per path
    const pathTrips = selected.flatMap((it) => {
      const nodes = root.getByHash(it).filter((it) => shouldShowNode(it));

      return nodes.map((it) => ({ path: it.path, trips: it.trips }));
    });

    const getTrips = (maybeParent: number[], fallbackTrips: number) => {
      const trips = pathTrips
        .filter((maybeChild) => LinkNode.isChild(maybeParent, maybeChild.path))
        .reduce((acc, it) => acc + it.trips, 0);

      return trips === 0 ? fallbackTrips : trips;
    };

    // Create segment network
    interface TripsInfo {
      trips: number;
      processingFailures: number;
      privacyTrims: number;
      childrenTrips: number;
    }
    type Segment = { nodes: LinkNode[]; hash: string };
    const Segment = {
      trips: (s: Segment) => {
        return s.nodes
          .filter((it) => shouldShowNode(it))
          .reduce((acc, it) => acc + getTrips(it.path, it.trips), 0);
      },
      tripInfo: (s: Segment): TripsInfo => {
        return s.nodes
          .filter((it) => shouldShowNode(it))
          .reduce(
            (acc, it) => {
              acc.trips += getTrips(it.path, it.trips);
              acc.processingFailures += it.processingFailures;
              acc.privacyTrims += it.privacyTrims;
              acc.childrenTrips += it.children.reduce(
                (acc, it) => acc + it.trips,
                0,
              );
              return acc;
            },
            {
              trips: 0,
              processingFailures: 0,
              privacyTrims: 0,
              childrenTrips: 0,
            },
          );
      },
    };
    const edges = new Map<string, Set<string>>();
    const parents = new Map<string, Set<string>>();
    const segments = new Map<string, Segment>();
    const getChildren = (hash: string) => {
      const children = edges.get(hash);
      if (children) {
        return [...children];
      }
      return [];
    };
    root.forEach((node) => {
      if (!shouldShowNode(node)) return;

      const outgoing = node.children
        .filter((it) => shouldShowNode(it))
        .map((it) => it.geometryHash);

      if (!edges.has(node.geometryHash)) {
        edges.set(node.geometryHash, new Set(outgoing));
      } else {
        const set = edges.get(node.geometryHash);
        outgoing.forEach((it) => {
          set?.add(it);
        });
      }
      outgoing.forEach((it) => {
        if (!parents.has(it)) {
          parents.set(it, new Set());
        }
        const set = parents.get(it);
        set?.add(node.geometryHash);
      });

      if (!segments.has(node.geometryHash)) {
        segments.set(node.geometryHash, {
          nodes: [node],
          hash: node.geometryHash,
        });
      } else {
        const segment = segments.get(node.geometryHash);
        segment?.nodes.push(node);
      }
    });

    type Group = { segments: Segment[]; first: Segment; last: Segment };
    const Group = {
      trips: (g: Group) => {
        return Segment.trips(g.segments[Math.floor(g.segments.length / 2)]);
      },
    };
    const groupsMap = new Map<string, Group>();
    const groups: Group[] = [];

    // Make groups
    const q = [root.geometryHash];
    const visited = new Set<string>();
    while (q.length) {
      const currentHash = q.pop() as string;
      const current = segments.get(currentHash);
      if (visited.has(currentHash)) continue;
      if (current === undefined) continue;
      visited.add(currentHash);

      const children = getChildren(currentHash);

      let group = groupsMap.get(currentHash);
      if (!group) {
        group = { segments: [current], first: current, last: current };
        groupsMap.set(currentHash, group);
        groups.push(group);
      }

      const maybeChild = segments.get(children[0]) as Segment;
      const maybeChildParents = parents.get(children[0]);
      const shouldMerge = (tripsA: number, tripsB: number) => {
        const maxTripDiff = 5;
        return Math.abs(tripsA - tripsB) < maxTripDiff;
      };
      if (
        currentHash !== root.geometryHash &&
        children.length === 1 &&
        maybeChildParents?.size === 1 &&
        shouldMerge(Segment.trips(maybeChild), Segment.trips(current))
      ) {
        const childHash = children[0];
        const child = segments.get(childHash) as Segment;
        group.segments.push(child);

        const childChildren = getChildren(childHash);
        if (childChildren.includes(group.first.hash)) {
          group.first = child;
        }

        const lastSegmentChildren = getChildren(group.last.hash);
        if (lastSegmentChildren.includes(childHash)) {
          group.last = child;
        }

        group.segments.forEach((it) => {
          groupsMap.set(it.hash, group);
        });
      }
      q.push(...children);
    }

    // make features
    const features: Feature<LineString, StyledSegmentProperties>[] = [];
    let selectedLink: Feature<LineString, StyledSegmentProperties> | undefined;
    let selectedSegmentsGeometry = selected
      .map((hash) => {
        const segment = segments.get(hash);
        if (!segment) return;
        return lineString(
          segment.nodes[0].coordinates,
          makeSegmentStyle(Segment.trips(segment), root.trips, getColor, unit),
        );
      })
      .filter(Boolean);

    groups.forEach((it) => {
      let segment: Segment | undefined = it.first;
      let coords = [...segment.nodes[0].coordinates];

      while (segment !== it.last) {
        const children = getChildren(segment.hash);
        segment = it.segments.find((it) => children.includes(it.hash));
        if (!segment) break;
        if (root.type === TreeType.Out) {
          coords.push(...segment.nodes[0].coordinates);
        } else {
          coords.unshift(...segment.nodes[0].coordinates);
        }
      }

      const trips = Group.trips(it);
      const feature = lineString(
        coords,
        makeSegmentStyle(trips, root.trips, getColor, unit),
      );

      if (it.first.nodes[0].geometryHash === root.geometryHash) {
        selectedLink = feature;
      } else {
        features.push(feature);
      }
    });

    // make icons
    const icons = [];

    let i = 0;
    for (const segment of segments.values()) {
      const node = segment.nodes[0];
      const inOutOffset = root.type === TreeType.In ? 180 : 0;
      const tipBearing = node.tipBearing + inOutOffset;
      const children = getChildren(segment.hash);

      // Determine icon color
      const group = groupsMap.get(segment.hash) as Group;
      const trips = Group.trips(group);
      const normalValue = (trips / root.trips) * 100;
      const percentTrips = Math.round(normalValue);
      const colorIndex = Math.floor(percentTrips / 10);
      const width = makeSegmentStyle(trips, root.trips, getColor, unit)[
        'line-width'
      ];

      const childParents = parents.get(children[0]) as Set<string>;

      node.tip.properties['id'] = i++;
      node.tip.properties['icon-size'] = 0.5;

      const tripsInfo = Segment.tripInfo(segment);

      node.tip.properties['processing-failures'] = tripsInfo.processingFailures;
      node.tip.properties['privacy-trims'] = tripsInfo.privacyTrims;

      node.tip.properties['trips-lost'] =
        tripsInfo.trips -
        (tripsInfo.childrenTrips +
          tripsInfo.processingFailures +
          tripsInfo.privacyTrims);

      const baseWidth = 26;

      node.tip.properties['two-links-connection'] = false;

      if (children.length === 0) {
        // End of the branch
        node.tip.properties['icon-image'] = 'arrow-icon';
        node.tip.properties['icon-rotate'] = tipBearing;
      } else if (
        children.length > 1 ||
        (children.length === 1 && childParents.size > 1)
      ) {
        // Intersection
        node.tip.properties['icon-image'] = 'arrow-intersection';

        node.tip.properties['icon-size'] = 0.2 + width / baseWidth;
      } else if (children.length === 1 && childParents.size <= 1) {
        // Add intersection icon for all connections between two segments to show trims info
        node.tip.properties['icon-image'] = 'arrow-intersection';
        node.tip.properties['icon-size'] = 0.2 + width / baseWidth;
        node.tip.properties['two-links-connection'] = true;
        // Arrow with direction
        // Parameters (degrees)
        const maxSimilarAngle = 20;
        const uTurnMaxAngle = 5;
        const turnMinAngle = 45;
        const turnMaxAngle = 110;

        let bearing = tipBearing;

        const childrenSegment = segments.get(children[0]) as Segment;

        const startBearing =
          childrenSegment.nodes[0].startBearing + inOutOffset;

        // Skip similar arrows
        if (Angle.absoluteDelta(tipBearing, startBearing) > maxSimilarAngle) {
          let turn = Angle.deltaAngle(tipBearing, startBearing);

          if (root.type === TreeType.In) {
            turn = -turn;
            bearing = startBearing;
          }

          if (Math.abs(Math.abs(turn) - 180) < uTurnMaxAngle) {
            node.tip.properties['icon-image'] = 'u-turn';
          } else if (turn < -turnMinAngle && turn > -turnMaxAngle) {
            node.tip.properties['icon-image'] = 'arrow-right';
          } else if (turn > turnMinAngle && turn < turnMaxAngle) {
            node.tip.properties['icon-image'] = 'arrow-left';
          }

          if (node.tip.properties['icon-image']) {
            node.tip.properties['icon-rotate'] = bearing;
          }
        }
      }

      node.tip.properties['icon-image-color-index'] = colorIndex;

      icons.push(node.tip);
    }

    return {
      links: featureCollection(features),
      icons: featureCollection(icons),
      selectedLink,
      selectedSegmentsGeometry,
    };
  }, [root, getColor, unit, selectedSegments, shouldShowNode]);

  // All non-hidden links geometry
  const allLinks = useMemo(() => {
    const segments = new Map<
      string,
      Feature<LineString, UnstyledSegmentProperties>
    >();
    root.forEach((it) => {
      if (!shouldShowNode(it)) return;

      if (!segments.has(it.geometryHash)) {
        segments.set(
          it.geometryHash,
          lineString(it.coordinates, {
            hash: it.geometryHash,
          }),
        );
      }
    });

    let links = [...segments.values()].slice(1);

    return featureCollection(links);
  }, [root, shouldShowNode, unit]);

  // Hover
  const hoverSegments = useMemo(() => {
    const hashes = new Set();

    let features: Feature[] = [];

    for (const node of hover.nodes) {
      if (!shouldShowNode(node)) continue;
      root.forEachInPath(node.path, (it) => {
        if (!shouldShowNode(it)) return;
        if (hashes.has(it.geometryHash)) return;
        features.push(lineString(it.coordinates));
        hashes.add(it.geometryHash);
      });
    }

    return features;
  }, [hover]);

  return { allLinks, ...geometry, hoverSegments };
};
