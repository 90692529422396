import { eachDayOfInterval } from 'date-fns';
import { dateToDto } from 'logic/time/dateFormat';
import { DayOfWeek } from 'model/DayOfWeek';
import { DateRangeFromDto } from './DateRanges/DateRange';

export const isWeekdaysActive = (state: string[]) =>
  !state.includes('SUNDAY') &&
  !state.includes('SATURDAY') &&
  state.length === 5;

export const isEveryDayActive = (state: string[]) => state.length === 7;

export const isWeekendActive = (state: string[]) =>
  state.includes('SATURDAY') && state.includes('SUNDAY') && state.length === 2;

export const getAllDaysFromRangeByDay = (
  range: DateRangeFromDto,
  day: DayOfWeek,
): string[] => {
  const eachDay = eachDayOfInterval(range);
  const dayIndex = Object.values(DayOfWeek).indexOf(day);
  return eachDay
    .filter((d) => d.getDay() === (dayIndex + 1) % 7)
    .map((d) => dateToDto(d));
};

export const createIsDayActive = (range: DateRangeFromDto) => (
  day: DayOfWeek,
): boolean => {
  const allDays = getAllDaysFromRangeByDay(range, day);
  return !allDays.every((d) => range.exclusions.includes(d));
};

export const isDateInActiveDays = (
  date: Date,
  activeDays: DayOfWeek[],
): boolean => {
  const day = Object.values(DayOfWeek).find(
    (_, i) => (i + 1) % 7 === date.getDay(),
  );
  return day ? activeDays.includes(day) : false;
};
