import { ValidationIcon } from 'components/UI/ValidationIcon/ValidationIcon';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Label } from 'tombac';
import { AsideValidation, Spacer } from '../components/UI/FormUI';

export interface Requirement<Suspect> {
  label: string | ((it: Suspect) => string);
  /** Return true if requirement check has failed */
  check: (it: Suspect) => boolean;
  fixer?: (it: Suspect) => any;
  fixerLabel?: string;
}

export function useValidation<Suspect>(
  requirements: Array<Requirement<Suspect>>,
  suspect: Suspect,
  setSuspect: (it: Suspect) => any,
) {
  const [shouldShow, setShouldShow] = useState(false);

  const failed = useMemo(() => {
    return requirements.filter((it) => it.check(suspect));
  }, [requirements, suspect]);

  // If user fixed an error after validating
  // we should wait for the next submit event
  useEffect(() => {
    if (shouldShow === true && failed.length === 0) {
      setShouldShow(false);
    }
  }, [failed]);

  const makeLabel = (
    label: string | ((it: Suspect) => string),
    suspect: Suspect,
  ) => (typeof label === 'function' ? label(suspect) : label);

  return {
    reset: () => {
      setShouldShow(false);
    },
    canSubmit: () => {
      setShouldShow(true);
      return failed.length === 0;
    },
    inputLabel: function ValidationInputLabel(
      name: string,
      requirement: Requirement<Suspect>,
    ) {
      const failedRequirement = failed.find(
        (it) =>
          makeLabel(it.label, suspect) ===
          makeLabel(requirement.label, suspect),
      );
      return (
        <Fragment>
          <Label>{name}</Label>
          {failedRequirement !== undefined && shouldShow && (
            <ValidationIcon isValid={false}>
              {makeLabel(failedRequirement.label, suspect)}
            </ValidationIcon>
          )}
        </Fragment>
      );
    },
    /**
     * Check if given requirement failed
     */
    failed: (r: Requirement<Suspect>) => {
      if (!shouldShow) {
        return false;
      }
      return (
        failed.find(
          (it) => makeLabel(it.label, suspect) === makeLabel(r.label, suspect),
        ) !== undefined
      );
    },
    showErrors: shouldShow ? failed.length === 0 : true,
    isValid: failed.length === 0,
    messagesPlain:
      shouldShow && failed.length > 0
        ? failed.map((it) => makeLabel(it.label, suspect))
        : undefined,
    messages: shouldShow
      ? failed.map((it, i) => (
          <AsideValidation key={i}>
            {makeLabel(it.label, suspect)}
            <Spacer />
            {it.fixer && (
              <>
                <Button
                  variant="danger"
                  size="s"
                  onClick={() => it.fixer && setSuspect(it.fixer(suspect))}
                >
                  {it.fixerLabel ?? 'Quick fix'}
                </Button>
              </>
            )}
          </AsideValidation>
        ))
      : null,
  };
}

export type Validation = ReturnType<typeof useValidation>;
