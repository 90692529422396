import { toCorrectFormat } from 'components/AnalysisNew/Date/utils';
import { DateRange, DateRangeFromDto } from 'components/AnalysisNew/DateRanges/DateRange';
import { formatDate } from 'components/AnalysisNew/DateRanges/DateUtils';
import { DayOfWeek } from 'model/DayOfWeek';
import { useMemo } from 'react';
import { useSettings } from 'user';
import { createExclusionsFilter, getExcludedMonths } from './exclusionFilter';

export type ExclusionMonthLabel = {
  type: 'month';
  value: [number, number];
  label: string;
};

export type ExclusionDateLabel = { type: 'date'; value: string; label: string };

export type ExclusionsLabel = ExclusionMonthLabel | ExclusionDateLabel;

const monthsLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const useExclusionsLabels = (
  dateRange: DateRangeFromDto,
  activeDays?: DayOfWeek[],
): ExclusionsLabel[] => {
  const settings = useSettings();
  const excludedMonths = getExcludedMonths(dateRange, activeDays);
  const exclusions = useMemo(
    () =>
      createExclusionsFilter(dateRange)
        .addFilterByActiveDays(activeDays)
        .addFilterByActiveMonths(excludedMonths)
        .filter(),
    [dateRange, activeDays, excludedMonths],
  );
  const months: ExclusionMonthLabel[] = excludedMonths.map((m) => {
    const [year, month] = m.split('-');
    return {
      type: 'month',
      label: `${monthsLabels[Number(month) - 1]} ${year}`,
      value: [Number(month), Number(year)],
    };
  });

  const dates: ExclusionDateLabel[] = exclusions.map((d) => ({
    type: 'date',
    label: formatDate(toCorrectFormat(d), settings?.dateFormat),
    value: d,
  }));

  return [...months, ...dates];
};
