import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { Provider } from 'react-redux';
import { TombacApp } from 'tombac';
import { getReduxStore } from '../../..';
import { withMap } from './withMap';
import './Popup.css';

interface Props {
  map: mapboxgl.Map;
  position: mapboxgl.LngLatLike;
  anchor: mapboxgl.Anchor;
  offset: number;
  maxWidth?: number;
  closeButton?: boolean;
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
}

class Popup extends Component<Props> {
  public componentDidMount() {
    this.createPopup();
  }

  private popup: mapboxgl.Popup | undefined;
  private div: HTMLDivElement | undefined;

  private createPopup() {
    const mapbox = this.props.map;
    const {
      position,
      anchor = 'bottom',
      offset,
      className,
      maxWidth = 240,
      closeButton,
    } = this.props;
    this.popup = new mapboxgl.Popup({
      anchor,
      offset,
      className,
      maxWidth: maxWidth.toString(),
      closeButton,
    })
      .setLngLat(position)
      .setDOMContent(this.childrenToDom())
      .addTo(mapbox);

    this.popup.on('close', () => {
      this.handleCloseClick();
    });
  }

  private handleCloseClick = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private childrenToDom() {
    this.div = document.createElement('div');
    const root = createRoot(this.div);
    root.render(
      <TombacApp>
        <Provider
          store={
            getReduxStore() as React.ComponentProps<typeof Provider>['store']
          }
        >
          {this.props.children}
        </Provider>
      </TombacApp>,
    );
    return this.div;
  }

  public componentDidUpdate() {
    this.popup?.setDOMContent(this.childrenToDom());
  }

  public componentWillUnmount() {
    const mapbox = this.props.map;
    if (mapbox) {
      mapbox.off('close', this.handleCloseClick);
      this.popup?.remove();
      this.div?.remove();
    }
  }

  public render() {
    return null;
  }
}

export default withMap(Popup);
