import { LinkNode } from 'components/SelectedLinkPage/LinkNode';

export enum SelectedLinkUnit {
  Trips,
  Percents,
}

export enum View {
  INCOMING,
  OUTGOING,
  ALL,
}

export type SelectedSegment = {
  segmentHash: string;
};

export interface NodeFilter {
  exclude: Set<LinkNode>;
}

export interface StyledSegmentProperties {
  'text-size': number;
  'text-color': string;
  'line-width': number;
  'line-color': string;
  text: string;
  percent: number;
  trips: number;
}
export interface SegmentInfo {
  index: number;
  totalTrips: number;
  frc: number;
  coords: [number, number][];
  bearing: number;
}

export interface SegmentStats extends SegmentInfo {
  paths: {
    node: LinkNode;
    name: string;
    index: number;
    style: StyledSegmentProperties;
  }[];
}
