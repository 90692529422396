import { DateRangeDto } from 'model/TimeDto';
import { differenceInDays } from 'date-fns';
import { dateToDto } from 'logic/time/dateFormat';
import { toCorrectFormat } from '../Date/utils';

export interface DateRange {
  start?: Date;
  end?: Date;
  exclusions: string[];
  name?: string;
}

export interface DateRangeFromDto extends Omit<DateRange, 'start' | 'end'> {
  start: Date;
  end: Date;
}

export const DateRange = (
  start?: Date,
  end?: Date,
  exclusions?: string[],
  name?: string,
): DateRange => ({
  start,
  end,
  exclusions: exclusions ?? [],
  name,
});

export const dateRangeFromDto = (dto: DateRangeDto): DateRangeFromDto => {
  return {
    start: new Date(toCorrectFormat(dto.startDate)),
    end: new Date(toCorrectFormat(dto.endDate)),
    exclusions: dto.exclusions ?? [],
    name: dto?.name,
  };
};

export const dateRangeToDto = (range: DateRange): DateRangeDto | undefined => {
  if (!range.start || !range.end) {
    return;
  }

  return {
    startDate: dateToDto(range.start),
    endDate: dateToDto(range.end),
    exclusions: range.exclusions,
    name: range.name,
  };
};

export const dateRangeFromLimit = ({ from, to }: { from: Date; to: Date }) => ({
  start: from,
  end: to,
  exclusions: [],
});

export const clampDate = (
  date: Date,
  limits: { start: Date; end: Date },
): Date => {
  if (differenceInDays(date, limits.start) < 0) {
    return limits.start;
  }
  if (differenceInDays(date, limits.end) > 0) {
    return limits.end;
  }
  return date;
};

/**
 * Constrain range to lie between limits
 */
export const clamp = (range: DateRange, limits: DateRange): DateRange => {
  let start = range.start;
  let end = range.end;
  if (range.start && range.end && limits.start && limits.end) {
    start = clampDate(range.start, { start: limits.start, end: limits.end });
    end = clampDate(range.end, { start: limits.start, end: limits.end });
  }
  // If range is within limits return the same reference to object
  if (range.start === start && range.end === end) {
    return range;
  }

  return DateRange(start, end);
};

export const durationInDays = (range: DateRange): number => {
  return range.start && range.end
    ? differenceInDays(range.end, range.start)
    : 0;
};

export const withEnd = (range: DateRange, end: Date): DateRange => ({
  ...range,
  start: range.start,
  end,
});
export const withStart = (range: DateRange, start: Date): DateRange => ({
  ...range,
  end: range.end,
  start,
});

export const isWithin = (
  range: DateRangeFromDto,
  limit: { start: Date; end: Date },
): boolean => {
  return (
    range.start >= limit.start &&
    range.start <= limit.end &&
    range.end >= limit.start &&
    range.end <= limit.end
  );
};
