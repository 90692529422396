import * as React from 'react';
import styled from 'styled-components';
import { tombac } from 'tombac';
import { LinkNode } from '../LinkNode';
import { SegmentViz } from '../SelectedLinkInfo/SegmentInfo';
import { SelectedLinkInfoTitle } from '../SelectedLinkInfo/SelectedLinkInfoHeaderTitle';
import { NodeFilter, SegmentStats } from 'model/SelectedLink';
import { BearingIcon } from '../SelectedLinkInfo/BearingIcon';

const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-family: ${tombac.fontFamily};
  height: 32px;
  padding: 0 20px 0 10px;

  :hover {
    background: #f2f2f2;
    cursor: pointer;
  }
`;

interface Props {
  segmentName: string;
  tree: LinkNode;
  selectedSegmentStats: SegmentStats;
  nodeFilter: NodeFilter;
  isSelectedLinkRegionAnalysis: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const SelectedLinkContextMenuItem: React.FC<Props> = ({
  segmentName,
  tree,
  selectedSegmentStats,
  nodeFilter,
  isSelectedLinkRegionAnalysis,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const path = React.useMemo(() => {
    const firstPath = selectedSegmentStats.paths[0];

    return {
      ...firstPath,
      style: {
        ...firstPath.style,
        'line-width': (firstPath.style['line-width'] ?? 0) / 2,
      },
    };
  }, [selectedSegmentStats]);

  return (
    <ListItem
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <BearingIcon bearing={selectedSegmentStats.bearing} />
      <SegmentViz
        path={path}
        nodeFilter={nodeFilter}
        width={20}
        $margin="0 8px 0 2px"
      />
      <SelectedLinkInfoTitle
        segmentName={segmentName}
        treeType={tree.type}
        segment={selectedSegmentStats}
        isSelectedLinkRegion={isSelectedLinkRegionAnalysis}
        hideFrc
        noTooltip
      />
    </ListItem>
  );
};
