import mapboxgl from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { useMap } from 'legoland-shared';

export const useMapEvent = (
  type: keyof mapboxgl.MapEventType,
  fn: (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void,
) => {
  const { map } = useMap();

  const fnRef = useRef(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  useEffect(() => {
    const handler = (event: any) => {
      fnRef?.current(event);
    };

    map.on(type, handler);

    return () => {
      map.off(type, handler);
    };
  }, [type]);
};
