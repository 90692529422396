import format from 'date-fns/format';
import { isSameDay } from 'date-fns';
import { DateTemplate } from 'model/TemplateDto';
import { DateRange } from './DateRange';
import { DateRangeDto } from 'model/TimeDto';

type DateFormat = 'DDMMYYYY' | 'MMDDYYYY' | 'YYYYMMDD';
export const defaultDateFormat: DateFormat = 'DDMMYYYY';

/**
 * @param rawDate If type of string, is expected to be in format `yyyy-MM-dd`.
 * @returns If `rawDate` is of type Date, returns it with no changes. If `string` then a `Date` at hour 00:00 in the timezone according to user's locale settings.
 */
const parseRawDate = (rawDate: Date | string): Date => {
  if (rawDate instanceof Date) {
    return rawDate;
  }
  const [year, month, day] = rawDate.split('-').map(parseNumber);
  return year && month && day
    ? new Date(year, month - 1, day)
    : new Date(rawDate);
};

const parseNumber = (value: string): number | undefined =>
  !isNaN(Number(value)) ? Number(value) : undefined;

export const parseDate = (
  formattedDate: string,
  dateFormat: DateFormat = defaultDateFormat,
  separator = '/',
): Date => {
  const parsers: { [key in DateFormat]: (s: string) => Date } = {
    DDMMYYYY: (s: string) => {
      const [dd, mm, yyyy] = s.split(separator).map((s) => Number(s));
      return new Date(yyyy, mm - 1, dd);
    },
    MMDDYYYY: (s: string) => {
      const [mm, dd, yyyy] = s.split(separator).map((s) => Number(s));
      return new Date(yyyy, mm - 1, dd);
    },
    YYYYMMDD: (s: string) => {
      const [yyyy, mm, dd] = s.split(separator).map((s) => Number(s));
      return new Date(yyyy, mm - 1, dd);
    },
  };

  return parsers[dateFormat as DateFormat](formattedDate);
};

/**
 * @param rawDate If type of string, is expected to be in format `yyyy-MM-dd`.
 * @param dateFormat Target format
 * @returns Date formatted as string using given `dateFormat`
 */
export const formatDate = (
  rawDate: Date | string,
  dateFormat?: string,
): string => {
  const parsedDate = parseRawDate(rawDate);
  const formatters: {
    [key: string]: (date: Date) => string;
  } = {
    DDMMYYYY: (date: Date) => format(date, `dd/MM/yyyy`),
    MMDDYYYY: (date: Date) => format(date, `MM/dd/yyyy`),
  };

  return formatters[dateFormat || defaultDateFormat](parsedDate);
};

export const formatTime = (
  rawDate: Date | string,
  clockFormat: string,
): string => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;
  const formatter = clockFormat === '24' ? 'HH:mm' : 'hh:mm a';
  return format(date, formatter);
};

export const validDate = (s: Date): boolean => {
  if (s.toString() === 'Invalid Date') {
    return false;
  }
  return s.getFullYear() >= 2000;
};

export const isSameDateRangeDtoAsDateRange = (
  a?: DateRange,
  b?: DateRangeDto,
): boolean => {
  const aExclusions = getExclusions(a?.exclusions);
  const bExclusions = getExclusions(b?.exclusions);
  const isStartSame: boolean =
    !!a?.start &&
    !!b?.startDate &&
    isSameDay(a.start, parseRawDate(b.startDate));
  const isEndSame: boolean =
    !!a?.end && !!b?.endDate && isSameDay(a.end, parseRawDate(b.endDate));

  return isStartSame && isEndSame && aExclusions === bExclusions;
};

export const isTemplateMatchingDateRange = (
  template: DateTemplate,
  dateRange: DateRange,
): boolean => {
  if (dateRange === undefined) {
    return false;
  }

  const rangeExclusions = getExclusions(dateRange?.exclusions);
  const templateExclusions = getExclusions(template?.exclusions);
  return (
    !!dateRange.start &&
    isSameDay(parseRawDate(template.fromDate), dateRange.start) &&
    !!dateRange.end &&
    isSameDay(parseRawDate(template.toDate), dateRange.end) &&
    templateExclusions === rangeExclusions
  );
};

const getExclusions = (exclusions: string[] | undefined): string =>
  exclusions
    ? [...exclusions].sort((a, b) => a.localeCompare(b)).join(',')
    : '';

export const isSameDateDtoAsTemplate = (
  range: DateRangeDto,
  template: DateTemplate,
): boolean => {
  const rangeExclusions = getExclusions(range.exclusions);
  const templateExclusions = getExclusions(template.exclusions);

  return (
    !!range.startDate &&
    isSameDay(parseRawDate(template.fromDate), parseRawDate(range.startDate)) &&
    !!range.endDate &&
    isSameDay(parseRawDate(template.toDate), parseRawDate(range.endDate)) &&
    rangeExclusions === templateExclusions
  );
};

export const getRangeName = (
  range: DateRangeDto,
  dateFormat: string,
): string => {
  if (range.name) {
    return range.name;
  }
  return `${range.startDate ? formatDate(range.startDate, dateFormat) : ''} — 
  ${range.endDate ? formatDate(range.endDate, dateFormat) : ''}`;
};
