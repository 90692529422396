import { Box, Button, HFlex } from 'tombac';
import { CancelIcon } from 'tombac-icons';
import { useMap } from 'legoland-shared';
import { TreeType } from '../LinkNode';
import { BoldText, DefaultText, GreyText } from 'components/UI/Text.style';
import { Bearing } from './Bearing';
import { SelectedLinkInfoTitle } from './SelectedLinkInfoHeaderTitle';
import { SegmentInfo, SelectedSegment } from 'model/SelectedLink';

interface Props {
  treeType: TreeType;
  maxTrips: number;
  segment: SegmentInfo;
  setSelectedSegments: (segments: SelectedSegment[]) => void;
  selectedSegments: SelectedSegment[];
  isSelectedLinkRegion: boolean;
}

export const SelectedLinkInfoHeader: React.FC<Props> = ({
  treeType,
  segment,
  maxTrips,
  setSelectedSegments,
  selectedSegments,
  isSelectedLinkRegion,
}: Props) => {
  const { map } = useMap();
  const removeSegment = (index: number) => {
    setSelectedSegments(selectedSegments.filter((_, i) => index !== i));
  };

  return (
    <Box
      $display="flex"
      $alignItems="center"
      $position="relative"
      $borderBottom="solid 1px #e5e5e5"
      $p="1sp 2sp"
    >
      <Box $width="100%">
        <SelectedLinkInfoTitle
          treeType={treeType}
          segment={segment}
          isSelectedLinkRegion={isSelectedLinkRegion}
          bold
        />

        <HFlex $justifyContent="space-between">
          <HFlex $alignItems="center" $gap="2px">
            <DefaultText>Total trips:</DefaultText>
            <BoldText>{segment.totalTrips.toLocaleString()}</BoldText>
            <GreyText $ml="1sp">
              {((segment.totalTrips / maxTrips) * 100).toFixed(2)}%
            </GreyText>
          </HFlex>
          <Bearing
            map={map}
            variant="simple"
            coords={segment.coords}
            bearing={segment.bearing}
          />
        </HFlex>
      </Box>

      <Box $position="absolute" $top="0" $right="0">
        <Button
          onClick={() => removeSegment(segment.index)}
          shape="circle"
          size="s"
          variant="flat"
        >
          <CancelIcon />
        </Button>
      </Box>
    </Box>
  );
};
