import { ReverseGeocodeApi } from 'api/ReverseGeocodeApi';
import { useEffect, useState } from 'react';
import { Caption, TooltipOnHover } from 'tombac';

interface Props {
  /**
   * If not provided, will fetch from reverse geocoder.
   */
  segmentName?: string;
  coords: [number, number];
  noTooltip?: boolean;
}

export const SegmentName: React.FC<Props> = ({
  segmentName,
  coords,
  noTooltip,
}) => {
  const [name, setName] = useState();

  useEffect(() => {
    if (segmentName) {
      return;
    }
    const abortController = new AbortController();
    const request = async () => {
      const name = await ReverseGeocodeApi.getRoadName(
        coords,
        abortController.signal,
      );

      setName(name ?? 'Segment');
    };

    request();

    return () => {
      abortController.abort();
    };
  }, [segmentName, coords[0], coords[1]]);

  const effectiveName = segmentName ?? name;

  if (noTooltip) {
    return <>{effectiveName}</>;
  }

  return (
    <TooltipOnHover
      placement="top"
      size="s"
      content={<Caption>{effectiveName}</Caption>}
    >
      {effectiveName}
    </TooltipOnHover>
  );
};
