import { Spacer } from 'components/UI/Stack/Spacer';
import { HStack, VStack } from 'components/UI/Stack/Stack';
import { RegionDto } from 'model/RegionDto';
import { DateRangeDto } from 'model/TimeDto';
import { Button, Modal } from 'tombac';
import { DateRange } from '../AnalysisNew/DateRanges/DateRange';
import FormattedDate from '../UI/FormattedDate/FormattedDate';
import React from 'react';

interface AnalysisCloneModalProperties {
  analysisName: string;
  invalidDateRanges: DateRangeDto[];
  invalidRegions: RegionDto[];
  onClose: () => void;
  onUnchangedClone: () => void;
  onCloneWithoutInvalidData: () => void;
  dateRangeLimit: DateRange;
  minDaysInDateRange: number;
}

export const AnalysisCloneModal: React.FC<AnalysisCloneModalProperties> = ({
  analysisName,
  invalidDateRanges,
  invalidRegions,
  onClose,
  onUnchangedClone,
  onCloneWithoutInvalidData,
  dateRangeLimit,
  minDaysInDateRange,
}) => {
  return (
    <Modal isOpen onRequestClose={onClose}>
      <div className="AnalysisListItem__clone-modal">
        <VStack spacing="xl">
          <h1>Limits exceeded</h1>
          <p>
            Analysis <b> {analysisName} </b> contains parameters, which now are
            exceeding your limits.
          </p>
          {invalidDateRanges.length > 0 && (
            <>
              <p>
                Date ranges exceeding{' '}
                <b>
                  <FormattedDate value={dateRangeLimit.start} />
                  {' - '}
                  <FormattedDate value={dateRangeLimit.end} />
                </b>{' '}
                or minimum <b>{minDaysInDateRange}</b> days in date range limit:
              </p>
              <ul>
                {invalidDateRanges.map((dateRange, i) => (
                  <li key={i}>
                    <FormattedDate value={new Date(dateRange.startDate)} /> -{' '}
                    <FormattedDate value={new Date(dateRange.endDate)} />
                  </li>
                ))}
              </ul>
            </>
          )}
          {invalidRegions.length > 0 && (
            <>
              <p>Regions exceeding allowed area limit:</p>
              <ul>
                {invalidRegions.map((region, i) => (
                  <li key={i}> {region.properties.name} </li>
                ))}
              </ul>
            </>
          )}
          <Spacer spacing="xxl" />
          <HStack>
            <Button variant="secondary" onClick={onCloneWithoutInvalidData}>
              Clone only valid parameters
            </Button>
            <Button onClick={onUnchangedClone}>Clone unchanged</Button>
            <Spacer />
            <Button onClick={onClose}>Cancel</Button>
          </HStack>
        </VStack>
      </div>
    </Modal>
  );
};
