import { ApiLinkButton } from 'components/AnalysisNew/Sections/ApiLinkButton';
import { formatDistance, formatDistanceStrict } from 'date-fns';
import format from 'date-fns/format';
import { trackEvent } from 'logic/tracking';
import * as React from 'react';
import Check from 'react-feather/dist/icons/check';
import Loader from 'react-feather/dist/icons/loader';
import Minus from 'react-feather/dist/icons/minus';
import MoreHorizontal from 'react-feather/dist/icons/more-horizontal';
import User from 'react-feather/dist/icons/user';
import X from 'react-feather/dist/icons/x';
import XSquare from 'react-feather/dist/icons/x-square';
import { Button } from 'tombac';
import { DeleteIcon, RedoIcon, StarIcon, StarOutlineIcon } from 'tombac-icons';
import analysisTypeName from '../../logic/analysis/analysisTypeName';
import { AnalysisInfo, AnalysisStatus } from '../../model/AnalysisDto';
import { TextEllipsis } from '../UI/UI';
import FormattedDate from '../UI/FormattedDate/FormattedDate';
import './AnalysisListItem.css';
import { AnalysisShareButton } from './AnalysisShareButton';
import { AnalysisStatusIndicator } from './AnalysisStatusIndicator';
import { estimatedDurationLabel } from './estimatedDuration';
import { AnalysisCloneButton } from './AnalysisCloneButton';
import { SimpleTooltip } from './SimpleTooltip';

const distanceInMinutes = (date1: any, date2: any) =>
  formatDistanceStrict(new Date(date1), new Date(date2), { unit: 'minute' });

const getAnalysisPercent = (analysis: any) =>
  Math.max((analysis.currentProgress / (analysis.maxProgress || 100)) * 100, 0);

const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

const formatTime = (
  { startAnalysisTime, endAnalysisTime }: any,
  formatter: any,
) => {
  if (startAnalysisTime == null) {
    return 'Unknown';
  } else if (endAnalysisTime == null) {
    return capitalizeFirstLetter(
      formatter(new Date(startAnalysisTime), new Date()),
    );
  } else {
    return capitalizeFirstLetter(
      formatter(new Date(startAnalysisTime), new Date(endAnalysisTime)),
    );
  }
};

interface AnalysisListItemProps {
  analysis: AnalysisInfo;
  showOwner?: boolean;
  canCancel?: boolean;
  showEstimate?: boolean;
  isArchived: boolean;

  openCancelModal: () => void;
  markAsFavorite: () => void;
  removeAsFavorite: () => void;
  archive: () => void;
  restore: () => void;
}

export const AnalysisListItem: React.FC<AnalysisListItemProps> = ({
  analysis,
  showOwner,
  canCancel,
  isArchived,
  openCancelModal,
  markAsFavorite,
  removeAsFavorite,
  archive,
  restore,
}) => {
  const cancelled = analysis.status === AnalysisStatus.CANCELLED;
  const failed = analysis.status === AnalysisStatus.FAILED;
  const ended = analysis.status === AnalysisStatus.ENDED;
  const running = analysis.status === AnalysisStatus.RUNNING;
  const waiting = analysis.status === AnalysisStatus.WAITING;
  const accepted = analysis.status === AnalysisStatus.ACCEPTED;
  const rejected = analysis.status === AnalysisStatus.REJECTED;
  const isFavorite = analysis.favorite;
  const canArchive = !isArchived && !running && !waiting && !ended;

  const displayCancel = canCancel && (running || waiting);

  const fave = markAsFavorite;
  const unfave = removeAsFavorite;

  let rowClass = 'analysis-item';
  if (cancelled || failed) {
    rowClass += ' analysis-item-disabled';
  }

  return (
    <>
      <div
        className={rowClass}
        onClick={() => {
          trackEvent({ action: 'report results', label: analysis.id });
        }}
        data-test-id="analysis-list-item"
      >
        <div
          className="analysis-item-name-wrapper"
          data-test-id="analysis-list-item-name"
        >
          <div className="analysis-item-type">
            {analysisTypeName(analysis.type)}
          </div>

          <TextEllipsis text={analysis.name} bold />
        </div>
        {showOwner && (
          <div className="analysis-item-owner" title={analysis.owner}>
            <User size={15} />
            <div className="analysis-item-owner-email">{analysis.owner}</div>
          </div>
        )}

        <div className="analysis-item-status">
          {accepted && (
            <AnalysisStatusIndicator
              variant={AnalysisStatus.ACCEPTED}
              icon={<Check size={14} />}
            >
              Ready
            </AnalysisStatusIndicator>
          )}
          {rejected && (
            <AnalysisStatusIndicator
              variant={AnalysisStatus.REJECTED}
              icon={<X size={14} />}
            >
              Rejected
            </AnalysisStatusIndicator>
          )}
          {running && (
            <AnalysisStatusIndicator
              variant={AnalysisStatus.RUNNING}
              icon={<Loader size={14} />}
              rotateIcon={true}
            >
              In progress {getAnalysisPercent(analysis).toFixed(0)}%
            </AnalysisStatusIndicator>
          )}
          {waiting && (
            <AnalysisStatusIndicator
              variant={AnalysisStatus.WAITING}
              icon={<MoreHorizontal size={14} />}
            >
              Queued
            </AnalysisStatusIndicator>
          )}
          {ended && (
            <AnalysisStatusIndicator variant={AnalysisStatus.ENDED} icon={'?'}>
              Need confirmation
            </AnalysisStatusIndicator>
          )}
          {cancelled && (
            <AnalysisStatusIndicator
              variant={AnalysisStatus.CANCELLED}
              icon={<Minus size={14} />}
            >
              Cancelled
            </AnalysisStatusIndicator>
          )}
          {failed && (
            <AnalysisStatusIndicator variant={AnalysisStatus.FAILED} icon={'!'}>
              Error
            </AnalysisStatusIndicator>
          )}
        </div>

        <div className="analysis-item-date" title="Created at">
          <FormattedDate value={new Date(analysis.creationTime)} />{' '}
          {format(new Date(analysis.creationTime), `H:mm`)}
        </div>
        <div
          className="analysis-item-duration"
          title={formatTime(analysis, distanceInMinutes)}
        >
          <div className="analysis-item-duration-label">
            {waiting ? 'Not started' : formatTime(analysis, formatDistance)}
          </div>
          {running && analysis.estimatedDuration && (
            <div className="analysis-item-estimated-duration">
              Estimated:{' '}
              {estimatedDurationLabel(
                analysis.estimatedDuration,
                analysis.startAnalysisTime,
                analysis.currentProgress,
              )}
            </div>
          )}
        </div>

        <div
          className="analysis-more analysis-item-actions"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ApiLinkButton analysisId={analysis.id} type={analysis.type} />
          {!isArchived && (
            <AnalysisShareButton
              analysisId={analysis.id}
              type={analysis.type}
              size="big"
            />
          )}
          {isArchived && (
            <SimpleTooltip content="Restore">
              <Button
                onClick={restore}
                shape="circle"
                $background="transparent"
              >
                <RedoIcon />
              </Button>
            </SimpleTooltip>
          )}
          {canArchive && (
            <SimpleTooltip content="Archive">
              <Button
                onClick={archive}
                shape="circle"
                $background="transparent"
              >
                <DeleteIcon />
              </Button>
            </SimpleTooltip>
          )}
          {!isArchived && (
            <AnalysisCloneButton
              analysisId={analysis.id}
              analysisType={analysis.type}
              variant="secondary"
              size="m"
              isTransparent
            />
          )}
          {displayCancel && (
            <SimpleTooltip content="Cancel">
              <Button
                shape="circle"
                onClick={openCancelModal}
                $background="transparent"
              >
                <XSquare size={20} style={{ marginTop: '6px' }} />
              </Button>
            </SimpleTooltip>
          )}

          {!isArchived && (
            <div
              style={{ opacity: isFavorite ? 1 : undefined, display: 'flex' }}
            >
              <SimpleTooltip content={'Favorite'}>
                <Button
                  onClick={isFavorite ? unfave : fave}
                  className={isFavorite ? 'Favorite--active' : undefined}
                  shape="circle"
                  $background="transparent"
                >
                  {isFavorite ? (
                    <StarIcon color="#61ade0" />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </Button>
              </SimpleTooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
