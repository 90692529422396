import mapboxgl from 'mapbox-gl';
import { Box } from 'tombac';
import { GreyText } from 'components/UI/Text.style';
import { SegmentShape } from './SegmentShape';
import { BearingIcon } from './BearingIcon';

interface Props {
  variant?: 'detailed' | 'simple';
  map: mapboxgl.Map;
  coords: [number, number][];
  bearing: number;
}

export const Bearing: React.FC<Props> = ({
  variant = 'simple',
  map,
  coords,
  bearing,
}: Props) => {
  return (
    <Box $display="flex" $alignItems="center" $ml="auto">
      {variant === 'detailed' ? (
        <SegmentShape map={map} coords={coords} />
      ) : (
        <BearingIcon bearing={bearing} />
      )}
      <GreyText>Direction</GreyText>
    </Box>
  );
};
