import { MatrixValuesType } from './../../components/AnalysisViewPage/ColoredMatrix/ColoredMatrix.types';
import chroma from 'chroma-js';
import { saveAs } from 'file-saver';
import { AnalysisInfo } from 'model/AnalysisDto';
import Workbook from 'workbook';
import { shortDayName, sortDays } from '../../components/AnalysisNew/Days/Days';
import {
  contrastTextColor,
  heatMapColor,
} from '../../components/AnalysisViewPage/MapFlows/colors';
import JSZip from 'js-xlsx/node_modules/jszip';
import { getActiveDays } from 'components/AnalysisNew/DateRanges/utils';
import { DateRangeFromDto } from 'components/AnalysisNew/DateRanges/DateRange';
import { dtoToDate } from 'logic/time/dateFormat';
import {
  defaultDateFormat,
  getRangeName,
} from 'components/AnalysisNew/DateRanges/DateUtils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
window.JSZip = JSZip;

function create2dTable(x: any): any[][] {
  const table = [];
  for (let i = 0; i < x; i++) {
    table[i] = [];
  }
  return table;
}
function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    // tslint:disable-next-line
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

function tableToSheet(table: any, percents = false) {
  const { min, max } = tableMinMax(table);
  const slice = table.transformedMatrix;
  const width = slice[0].array.length;
  const height = slice.length;
  const sheetData = create2dTable(height + 2);
  const via = table.regionLabels[table.viaRegion];

  sheetData[0][0] = via ? `via ${via}` : '';
  sheetData[1][0] = table.rotate
    ? 'Destinations\\Origins'
    : 'Origins\\Destinations';

  // Header
  for (let i = 0; i < width; i++) {
    sheetData[1][i + 1] =
      (table.header[i] && table.header[i].name) || 'External';
  }

  // Vertical names
  for (let i = 0; i < height; i++) {
    sheetData[i + 2][0] = table.regionLabels[slice[i].index] || 'External';
  }

  // Numbers
  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      const cellValue = slice[i].array[j];
      const bgColor = cellColor(min, max, cellValue);
      sheetData[i + 2][j + 1] = {
        v: percents ? cellValue / 100 : cellValue,
        t: 'n',
        s: {
          numFmt: percents ? '0.000%' : '0',
          fill: { fgColor: { rgb: colorToARGB(bgColor) } },
          font: {
            color: {
              rgb: textColor(bgColor),
            },
          },
        },
      };
    }
  }
  return sheetData;
}

function arrayToSheet(array: any, sheetName: string) {
  return new Workbook().addRowsToSheet(sheetName, array).finalize();
}

function tableMinMax(table: any) {
  let min = Infinity;
  let max = -Infinity;
  const matrix = table.transformedMatrix;
  matrix.forEach((row: { array: number[] }) => {
    row.array.forEach((cell: number) => {
      if (cell < min) {
        min = cell;
      }
      if (cell > max) {
        max = cell;
      }
    });
  });

  return { min, max };
}

function cellColor(min: number, max: number, value: number) {
  if (min === max) {
    return heatMapColor(0);
  }
  const scaled = ((value - min) / (max - min)) * 100;
  let log = (100 * Math.log(scaled)) / Math.log(100);
  if (log < 0) {
    log = 0;
  }
  if (log > 100) {
    log = 100;
  }

  return heatMapColor(log);
}

function textColor(bgColor: string) {
  return colorToARGB(contrastTextColor(bgColor));
}

function colorToARGB(color: string) {
  return (chroma(color) as any).hex('ARGB').split('#')[1];
}

function createTableHeader(table: any) {
  const header = table.rotate
    ? ['Destination', 'Origin']
    : ['Origin', 'Destination'];

  for (
    let resultIndex = 0;
    resultIndex < table.dateRange.length;
    resultIndex++
  ) {
    const startDate = table.dateRange[resultIndex].startDate;
    const endDate = table.dateRange[resultIndex].endDate;
    const startTime = table.timeRange[resultIndex].startTime;
    const endTime = table.timeRange[resultIndex].endTime;

    const dateTimeHeader =
      'Date range: ' +
      startDate +
      ' - ' +
      endDate +
      ' Time range: ' +
      startTime +
      ' - ' +
      endTime;
    header.push(dateTimeHeader);
  }
  return [header];
}

function createMultipleHeaders(table: any) {
  const header = table.rotate
    ? ['Destination', 'Origin']
    : ['Origin', 'Destination'];

  for (
    let resultIndex = 0;
    resultIndex < table.dateRange.length;
    resultIndex++
  ) {
    const startDate = table.dateRange[resultIndex].startDate;
    const endDate = table.dateRange[resultIndex].endDate;
    const startTime = table.timeRange[resultIndex].startTime;
    const endTime = table.timeRange[resultIndex].endTime;

    const dateTimeHeader =
      'Date range: ' +
      startDate +
      ' - ' +
      endDate +
      ' Time range: ' +
      startTime +
      ' - ' +
      endTime;

    const tripsHeader = `${dateTimeHeader} Trips`;
    const percentsHeader = `${dateTimeHeader} Percent`;
    header.push(tripsHeader, percentsHeader);
  }
  return [header];
}

function createTableContent(table: any, postfix = '') {
  const columnName = (index: number) =>
    table.header?.[index]?.name || 'External';
  const name = (index: number) => table.regionLabels[index];
  const tableContent: any[] = [];

  table.transformedMatrix.forEach(
    (row: { array: number[][]; index: number }) => {
      row.array.forEach((flows, columnIndex) => {
        tableContent.push([
          name(row.index),
          columnName(columnIndex),
          `${flows}${postfix}`,
        ]);
      });
    },
  );

  return tableContent;
}

function createMultipleTableContent(table: any) {
  const columnName = (index: number) =>
    table.header?.[index]?.name || 'External';
  const name = (index: number) => table.regionLabels[index];
  const tableContent: any[] = [];

  table.transformedMatrix.forEach(
    (row: {
      array: { trips: number; percents: number }[][];
      index: number;
    }) => {
      row.array.forEach((flows, columnIndex) => {
        tableContent.push(
          [name(row.index), columnName(columnIndex)].concat(
            ...flows.map(({ trips, percents }) => [trips, percents]),
          ),
        );
      });
    },
  );
  return tableContent;
}

export function downloadExcelWithAllResults(
  table: any,
  parameters: AnalysisInfo,
) {
  const header = createMultipleHeaders(table);
  const results = createMultipleTableContent(table);

  const allRows = header.concat(results);

  import(/* webpackChunkName: "xlsx"*/ 'js-xlsx').then((XLSX) => {
    const ws = arrayToSheet(allRows, 'Analysis');
    const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
    const wbout = XLSX.write(ws, wopts);
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${parameters.name}.xlsx`,
    );
  });
}

export function downloadExcel(
  table: any,
  parameters: AnalysisInfo,
  matrixValuesType: MatrixValuesType,
) {
  const sheetData = tableToSheet(table, matrixValuesType === 'RELATIVE');

  const { startDate, endDate } = table.dateRange[0];
  const { startTime, endTime } = table.timeRange[0];
  import(/* webpackChunkName: "xlsx"*/ 'js-xlsx').then((XLSX) => {
    const ws = arrayToSheet(sheetData, 'Analysis');

    const { zoneId } = parameters.timeDefinition;

    const dateRange: DateRangeFromDto = {
      ...table.dateRange[0],
      start: dtoToDate(table.dateRange[0].startDate),
      end: dtoToDate(table.dateRange[0].endDate),
    };

    const parametersData = [
      ['Analysis Name', parameters.name],
      ['Start Date', startDate],
      ['End Date', endDate],
      ['Start Time', startTime],
      ['End Time', endTime],
      ['Timezone', zoneId],
      [
        'Days Of Week',
        getActiveDays(dateRange)
          .sort(sortDays)
          .map((d) => shortDayName[d])
          .join(', '),
      ],
    ];

    const paramsWorkbook = arrayToSheet(parametersData, 'Parameters');
    ws.SheetNames.push(paramsWorkbook.SheetNames[0]);
    ws.Sheets.Parameters = paramsWorkbook.Sheets.Parameters;

    const fileNameSuffix = ` (${getRangeName(
      table.dateRange[0],
      defaultDateFormat,
    )})`;

    const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
    const wbout = XLSX.write(ws, wopts);
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${parameters.name}${fileNameSuffix}.xlsx`,
    );
  });
}

export function downloadCSV(
  table: any,
  parameters: AnalysisInfo,
  matrixValuesType: MatrixValuesType,
) {
  const header = createTableHeader(table);
  const results = createTableContent(
    table,
    matrixValuesType === 'RELATIVE' ? '%' : undefined,
  );
  const allRows = header.concat(results);

  downloadCSVArray(allRows, parameters.name);
}

export function downloadMultipleCSV(table: any, parameters: AnalysisInfo) {
  const header = createMultipleHeaders(table);
  const results = createMultipleTableContent(table);
  const allRows = header.concat(results);
  downloadCSVArray(allRows, parameters.name);
}

export function downloadCSVArray(array: string[][], name: string) {
  const csv = array.map((row) => row.join(',')).join('\n');
  const csvBlob = new Blob([csv], { type: 'text/csv' });

  saveAs(csvBlob, `${name}.csv`);
}
