import { useEffect } from 'react';
import { GeoJSONSource, Layer } from 'mapbox-gl';
import { Feature, FeatureCollection } from '@turf/helpers';
import { useMap } from 'legoland-shared';

const empty: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

const parseData = (
  data: FeatureCollection | Feature | Feature[] | undefined,
): GeoJSON.FeatureCollection => {
  if (data === undefined) {
    return empty;
  }
  if (data instanceof Array) {
    return {
      type: 'FeatureCollection',
      features: data as GeoJSON.Feature[],
    };
  }
  if (data.type === 'Feature') {
    return {
      type: 'FeatureCollection',
      features: [data as GeoJSON.Feature],
    };
  }
  return data as GeoJSON.FeatureCollection;
};

const useLayers = (
  id: string,
  layers: Layer[],
  data: FeatureCollection | Feature | Feature[] | undefined,
  beforeLayer?: string,
  generateId = false,
) => {
  const { map } = useMap();

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: parseData(data),
      generateId,
    });
    const before =
      beforeLayer && map.getLayer(beforeLayer) ? beforeLayer : undefined;
    layers.forEach((l) => map.addLayer({ ...l, source: id } as any, before));

    return () => {
      layers.forEach((l) => {
        if (map.getLayer(l.id)) {
          map.removeLayer(l.id);
        }
      });
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [id, layers, beforeLayer]);

  useEffect(() => {
    const source = map.getSource(id) as GeoJSONSource;

    source.setData(parseData(data));
  }, [data]);
};

export default useLayers;
