import { CellProps, Column } from 'react-table';
import { ResultData } from './useAnalysisSummary';
import styled from 'styled-components';
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AnalysisType } from 'model/AnalysisDto';
import { tombac, TooltipOnHover, useTombac, Text, Box } from 'tombac';
import { WarningIcon } from 'tombac-icons';
import { numberWithSpaces } from 'components/AnalysisViewPage/numberWithSpaces';
import { ExclusionsTooltip } from '../ExclusionsTooltip';
import { dateRangeFromDto } from 'components/AnalysisNew/DateRanges/DateRange';
import { useSettings } from 'user';
import {
  formatDate,
  formatTime,
  parseDate,
} from 'components/AnalysisNew/DateRanges/DateUtils';
import { DayOfWeek } from 'model/DayOfWeek';

const Square = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
  display: inline-block;
  margin-right: 6px;
`;

const TooltipContentContainer = styled.div`
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  max-width: 311px;
`;

const createDateFromTime = (time: string) =>
  new Date(new Date().toDateString() + ' ' + time);

const TimesetCell = ({ row }: CellProps<ResultData>) => {
  const { clockFormat } = useSettings();
  const { startTime, endTime } = row.original.timeRange;
  return (
    <div>
      <Square color={row.original.color} />
      {`${formatTime(
        createDateFromTime(startTime),
        clockFormat,
      )} - ${formatTime(createDateFromTime(endTime), clockFormat)}`}
    </div>
  );
};

const TripsCell = ({ value }: CellProps<ResultData, number>) => {
  const { analysis } = useAnalysisContext();
  const tombac = useTombac();
  const shouldRenderWarning =
    analysis.info.type === AnalysisType.FlowMatrix ? value === 0 : value < 5;

  const contnet = (
    <TooltipContentContainer>
      <span style={{ fontWeight: 400 }}>
        Results for this date and time range will be empty.{' '}
      </span>
      {analysis.info.type === AnalysisType.SelectedLink && (
        <div style={{ fontWeight: 300 }}>
          {' '}
          Due to privacy each road segment coming from the selected link needs
          to have at least 0.1% of all trips or at least 5 trips in case the
          value is lower than 0.1%.
        </div>
      )}
    </TooltipContentContainer>
  );

  return (
    <div>
      {numberWithSpaces(value)}{' '}
      {shouldRenderWarning && (
        <TooltipOnHover
          content={contnet}
          variant="inverted"
          usePortal
          placement="top"
          size="s"
        >
          <WarningIcon color={tombac.color('alert', 600)} />
        </TooltipOnHover>
      )}
    </div>
  );
};

export const DateRangeCell = ({ row }: CellProps<ResultData, string>) => {
  const dateRange = row.original.dateRange;
  const dateFormat = useSettings().dateFormat;
  const startDate: Date =
    typeof dateRange.startDate === 'string'
      ? parseDate(dateRange.startDate, 'YYYYMMDD', '-')
      : dateRange.startDate;
  const endDate: Date =
    typeof dateRange.endDate === 'string'
      ? parseDate(dateRange.endDate, 'YYYYMMDD', '-')
      : dateRange.endDate;

  return (
    <div>
      {dateRange.name ?? null}
      <Box $display="flex" $gap="4px" $alignItems="center">
        {`${formatDate(startDate, dateFormat)} - ${formatDate(endDate, dateFormat)}`}
        {(dateRange.exclusions ?? []).length > 0 && (
          <ExclusionsTooltip
            dateRange={dateRangeFromDto(dateRange)}
            activeDays={Object.values(DayOfWeek)}
          />
        )}
      </Box>
    </div>
  );
};

export const createColumns = (): Column<ResultData>[] => [
  {
    Header: 'Timeset',
    accessor: 'timeRange',
    Cell: TimesetCell,
  },
  {
    Header: 'Date range',
    accessor: 'dateRange',
    Cell: DateRangeCell,
  },
  { Header: 'Analysed trips', accessor: 'tripsCounted', Cell: TripsCell },
];
