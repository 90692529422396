import { useMemo } from 'react';
import styled from 'styled-components';
import { lineString } from '@turf/turf';
import { Box, HFlex } from 'tombac';
import { ArrowLightIcon } from 'tombac-icons';
import { TreeType } from '../LinkNode';
import { SegmentName } from './SegmentName';
import { BoldTextOverflow, DefaultText } from 'components/UI/Text.style';
import { RoadClass } from './RoadClass';
import { lineStringToReverseGeocodePoint } from 'components/Map/mapUtils';

const Title = styled(DefaultText)<{ bold: boolean; cursorPointer: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'inherit')};
`;

const TextOverflow = styled(BoldTextOverflow)<{
  bold: boolean;
  cursorPointer: boolean;
}>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'inherit')};
`;

const StyledArrowLightIcon = styled(ArrowLightIcon)`
  margin: 0 5px;
`;

interface Props {
  segmentName?: string;
  treeType: TreeType;
  segment: any;
  isSelectedLinkRegion: boolean;
  bold?: boolean;
  hideFrc?: boolean;
  cursorPointer?: boolean;
  noTooltip?: boolean;
}

export const SelectedLinkInfoTitle: React.FC<Props> = ({
  segmentName,
  treeType,
  segment,
  isSelectedLinkRegion,
  bold,
  hideFrc,
  cursorPointer,
  noTooltip,
}: Props) => {
  const linkName = isSelectedLinkRegion ? 'Region' : 'Link';

  const segmentCordsCenter = useMemo(() => {
    return lineStringToReverseGeocodePoint(lineString(segment.coords));
  }, [segment]);

  return (
    <HFlex $alignItems="center">
      {treeType === TreeType.In ? (
        <>
          <TextOverflow
            bold={!!bold}
            cursorPointer={cursorPointer}
            $maxWidth="225px"
          >
            <SegmentName
              segmentName={segmentName}
              coords={segmentCordsCenter}
              noTooltip={noTooltip}
            />
          </TextOverflow>
          <StyledArrowLightIcon size="xs" />
          <Title
            bold={!!bold}
            cursorPointer={cursorPointer}
            $color="rgba(0, 170, 255, 1)"
          >
            {linkName}
          </Title>
        </>
      ) : (
        <>
          <Title
            bold={!!bold}
            cursorPointer={cursorPointer}
            $color="rgba(0, 170, 255, 1)"
          >
            {linkName}
          </Title>
          <StyledArrowLightIcon size="xs" />
          <TextOverflow
            bold={!!bold}
            cursorPointer={cursorPointer}
            $maxWidth="225px"
          >
            <SegmentName
              segmentName={segmentName}
              coords={segmentCordsCenter}
              noTooltip={noTooltip}
            />
          </TextOverflow>
        </>
      )}
      {!hideFrc ? (
        <Box $ml="0.5sp">
          <RoadClass frc={segment.frc} />
        </Box>
      ) : null}
    </HFlex>
  );
};
