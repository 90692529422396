import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import AnalysisApi from 'api/AnalysisApi';
import { Button, Input, useToasts } from 'tombac';
import { AnalysisType } from 'model/AnalysisDto';

type urlResponse =
  | { url: string }
  | 'generating'
  | 'failed'
  | 'idle'
  | 'loading';

interface AnalysisShareProperties {
  analysisId: number;
  type: AnalysisType;
}

export const AnalysisShare: React.FC<AnalysisShareProperties> = ({
  analysisId,
  type,
}) => {
  const toast = useToasts();
  const [shareUrl, setShareUrl] = useState<urlResponse>('idle');
  const makeUrl = (token: string) =>
    `${location.origin}/share/${
      type === AnalysisType.SelectedLink ? 'selected-link/' : ''
    }${analysisId}?t=${token}`;

  useEffect(() => {
    setShareUrl('loading');
    const load = async () => {
      const token = await AnalysisApi.getSharedToken(analysisId);
      if (token !== '') {
        setShareUrl({ url: makeUrl(token) });
      } else {
        setShareUrl('idle');
      }
    };
    load();
  }, [analysisId]);

  const generateLink = async () => {
    setShareUrl('generating');
    try {
      const token = await AnalysisApi.share(analysisId);
      setShareUrl({ url: makeUrl(token) });
    } catch (e) {
      setShareUrl('failed');
    }
  };

  const copyLink = () => {
    if (!(shareUrl as any).url) {
      return;
    }
    copy((shareUrl as any).url);
    toast.addToast('Link copied to clipboard', 'success');
  };

  const deleteLink = async () => {
    await AnalysisApi.deleteSharedToken(analysisId);
    toast.addToast('Shareable link deleted', 'success');
    setShareUrl('idle');
  };

  return (
    <div
      style={{ margin: '1.5rem 0', width: '520px' }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {shareUrl === 'loading' && <>Loading</>}
      {shareUrl === 'idle' && (
        <>
          <p>
            You can create link that will allow anyone to view this analysis.
          </p>
          <Button onClick={generateLink}>Create sharable link</Button>
        </>
      )}
      {shareUrl === 'generating' && <Button>Generating...</Button>}
      {shareUrl === 'failed' && 'Failed to generate link'}
      {(shareUrl as any).url && (
        <>
          <p>
            Anyone with this link can view this analysis, so be careful not to
            share it with anyone you don't intend to!
          </p>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Input
              $width="300px"
              data-test="share-link-input"
              value={(shareUrl as any).url}
            />
            <Button onClick={copyLink}>Copy link</Button>
            <Button variant="flat" onClick={deleteLink}>
              Delete
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
