import { eachDayOfInterval, isAfter, isBefore, isSameDay } from 'date-fns';
import { dateToDto, dtoToDate } from 'logic/time/dateFormat';
import { DayOfWeek, invertDays } from 'model/DayOfWeek';
import { count, groupBy } from 'ramda';
import { DateRangeFromDto } from '../components/AnalysisNew/DateRanges/DateRange';
import {
  createIsDayActive,
  isDateInActiveDays,
} from '../components/AnalysisNew/dateRangeUtils';

const createIsMonthExcluded = (
  dateRange: DateRangeFromDto,
  activeDays?: DayOfWeek[],
) => ([monthYear, dates]: [string, Date[]]) => {
  const month = Number(monthYear.split('-')[1]);
  const year = Number(monthYear.split('-')[0]);
  const startDateOfMonth = new Date(year, month - 1, 1);
  const endDateOfMonth = new Date(year, month, 0);

  const startDate = isAfter(startDateOfMonth, dateRange.start)
    ? startDateOfMonth
    : dateRange.start;

  const endDate = isBefore(endDateOfMonth, dateRange.end)
    ? endDateOfMonth
    : dateRange.end;

  const eachDay = isSameDay(startDate, endDate)
    ? [startDate]
    : eachDayOfInterval({ start: startDate, end: endDate });
  const numberOfExcludedDays = activeDays
    ? count((d: Date) => !isDateInActiveDays(d, activeDays))(eachDay)
    : 0;

  return eachDay.length - numberOfExcludedDays === dates.length;
};

export const getExcludedMonths = (
  dateRange: DateRangeFromDto,
  activeDays?: DayOfWeek[],
) => {
  const exclusions = dateRange.exclusions.map(dtoToDate);
  const daysByMonthsYear = groupBy(
    (d: Date) => `${d.getFullYear()}-${d.getMonth() + 1}`,
  )(exclusions);

  const isMonthExcluded = createIsMonthExcluded(dateRange, activeDays);
  return Object.entries(daysByMonthsYear)
    .filter(isMonthExcluded)
    .map(([monthYear]) => monthYear);
};

export const getFilterByActiveDays = (activeDays: DayOfWeek[]) => {
  const invertedDays = invertDays(activeDays);
  return (date: Date) => {
    const day = Object.values(DayOfWeek).find(
      (_, i) => (i + 1) % 7 === date.getDay(),
    );

    return day !== undefined && !invertedDays.includes(day);
  };
};

const getFilterByActiveMonths = (excludedMonths: string[]) => {
  const monthsYears = excludedMonths.map((m) => {
    const [year, month] = m.split('-');
    return { year: Number(year), month: Number(month) };
  });
  return (date: Date) =>
    !monthsYears.some(({ year, month }) => {
      return date.getFullYear() === year && date.getMonth() + 1 === month;
    });
};

export const createExclusionsFilter = (
  dateRange: DateRangeFromDto,
  filters: ((date: Date) => boolean)[] = [],
) => {
  return {
    addFilterByActiveDays: (activeDays?: DayOfWeek[]) => {
      let filter: (date: Date) => boolean = () => true;
      if (activeDays) {
        filter = getFilterByActiveDays(activeDays);
      } else {
        const isActiveDay = createIsDayActive(dateRange);
        filter = getFilterByActiveDays(
          Object.values(DayOfWeek).filter(isActiveDay),
        );
      }
      return createExclusionsFilter(dateRange, [...filters, filter]);
    },
    addFilterByActiveMonths: (excludedMonths?: string[]) => {
      const filter = excludedMonths
        ? getFilterByActiveMonths(excludedMonths)
        : getFilterByActiveMonths(getExcludedMonths(dateRange));
      return createExclusionsFilter(dateRange, [...filters, filter]);
    },

    filter: () => {
      const dates = dateRange.exclusions.map(dtoToDate);
      return filters
        .reduce((acc, filter) => acc.filter(filter), dates)
        .map(dateToDto);
    },
  };
};
