import DraftApi, { AnalysisDraft } from 'api/DraftApi';
import { Loading } from 'components/UI/Loading';
import analysisTypeName from 'logic/analysis/analysisTypeName';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useMenu } from 'reducers/menuReducer';
import { Button, useToasts } from 'tombac';
import { DeleteIcon, DraftIcon } from 'tombac-icons';
import { useSettings } from 'user';
import { formatDate } from '../AnalysisNew/DateRanges/DateUtils';
import './AnalysisTable.css';
import { NoResultsFound } from './AnalysisTable';

interface DraftItemProps {
  draft: AnalysisDraft;
}

export const DraftItem: React.FC<DraftItemProps> = ({ draft }) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [, , { continueFromDraft }] = useMenu();
  const history = useHistory();
  const settings = useSettings();

  const restoreDraft = async () => {
    const fullDraft = await DraftApi.get(draft.id, draft.type);
    addToast('Restored from draft', 'success');
    continueFromDraft(fullDraft);
    history.push('/new/dates');
  };

  return (
    <div className="DraftItem" onClick={restoreDraft}>
      <div>
        <div className="DraftItem__type">{analysisTypeName(draft.type)}</div>
        <div className="DraftItem__name">{draft.name}</div>
      </div>
      <div className="DraftItem__date">
        {formatDate(draft.creationTime, settings?.dateFormat)}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <Button
          size="s"
          shape="circle"
          $background="transparent"
          variant="flat"
          onClick={async () => {
            await DraftApi.deleteDraft(draft.id, queryClient);
            addToast(`Draft ${draft.name} deleted`);
          }}
        >
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
};

interface DraftsListProps {
  drafts: AnalysisDraft[] | undefined;
}

export const DraftsList: React.FC<DraftsListProps> = ({ drafts }) => {
  if (drafts === undefined) {
    return <Loading />;
  }

  return (
    <>
      {drafts.length === 0 ? (
        <NoResultsFound
          subtitle={
            <>
              Your drafts will appear here. Create drafts by clicking '
              <DraftIcon /> Save as Draft' button while creating your report
            </>
          }
        />
      ) : (
        <>
          <div className="DraftsList__header">
            <div>Name</div>
            <div>Created on</div>
          </div>
          {drafts === undefined && <Loading />}
          {drafts !== undefined &&
            drafts.map((it) => <DraftItem draft={it} key={it.id} />)}
        </>
      )}
    </>
  );
};
