import area from '@turf/area';
import { Geometry } from '@turf/helpers';
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';
import IsValidOp from 'jsts/org/locationtech/jts/operation/valid/IsValidOp';
import IsSimpleOp from 'jsts/org/locationtech/jts/operation/IsSimpleOp';
import { RegionDto } from '../../../model/RegionDto';
import { ValidationStatus, ValidationResult } from './validation';
import { calculateArea } from 'components/AnalysisViewPage/MapFlows/calculateArea';

const REGION_MINIMUM_AREA = 0.00002;

const dtoArea = (acc: number, dto: RegionDto): number =>
  acc + calculateArea(dto);

/**
 * Area in km^2
 */
const calculateAllRegionsArea = (list: RegionDto[]): number =>
  list.reduce(dtoArea, 0);

const isGeoJsonTooSmall = (geojson: RegionDto): boolean => {
  if (geojson.geometry.type == 'LineString') {
    return false;
  }
  const featureArea = area(geojson) / 1000000;
  return featureArea < REGION_MINIMUM_AREA;
};

const hasSelfIntersections = (geojson: RegionDto): boolean => {
  if (geojson.geometry.type === 'LineString') {
    return false;
  }
  const geometry = toJtsGeometry(geojson.geometry);
  const validator = new IsSimpleOp(geometry);
  return !validator.isSimple();
};

const isTopologicallyValid = (geojson: RegionDto): ValidationResult => {
  const geometry = toJtsGeometry(geojson.geometry);
  const validator = new IsValidOp(geometry);
  const status = validator.isValid();
  const error = validator.getValidationError();
  let message = '';
  if (!status && validator.getValidationError()) {
    message = `${error.getMessage()} at or near point(${
      error.getCoordinate().x
    }, ${error.getCoordinate().y})`;
  }
  if (status) {
    return new ValidationResult(ValidationStatus.OK, message);
  } else {
    return new ValidationResult(ValidationStatus.INVALID, message);
  }
};

const toJtsGeometry = (geometry: Geometry): void => {
  const reader = new GeoJSONReader();
  return reader.read(geometry);
};

export {
  calculateAllRegionsArea as calculateArea,
  isGeoJsonTooSmall,
  hasSelfIntersections,
  isTopologicallyValid,
};
