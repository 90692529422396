import React from 'react';
import { GlMapProps, GlMap as LegolandGlMap } from 'legoland-shared';
import './GlMap.css';

export const apiKey = '1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv';
const LOCAL_STORAGE_ID = 'tomtom-od-map-style';

const GlMap: React.FC<
  Omit<GlMapProps, 'apiKey' | 'mapStyleSettings' | 'onMapStyleSettingsChange'>
> = (props) => {
  return (
    <LegolandGlMap
      apiKey={apiKey}
      mapStyleSettingsLocalStorageId={LOCAL_STORAGE_ID}
      {...props}
    />
  );
};

export default GlMap;
