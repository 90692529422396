import React, { useCallback } from 'react';
import { Caption, NavbarMenu, NavbarMenuItem, tombac } from 'tombac';
import './AnalysisTable.css';
import { NavBarLink } from 'components/UI/UI';
import { ListType } from './AnalysisTablePage';

export type AnalysisNavbarCounters = { [key in ListType]: number };

interface AnalysisNavbarProps {
  path: string;
  listType: ListType;
  onChange: (listType: ListType) => void;
  showDrafts?: boolean;
  counters: AnalysisNavbarCounters;
}
export const AnalysisNavBar: React.FC<AnalysisNavbarProps> = ({
  path,
  listType,
  onChange,
  showDrafts = true,
  counters,
}) => {
  const handleClick = useCallback((type: ListType) => () => onChange(type), [
    onChange,
  ]);

  const items: {
    name: string;
    path: string;
    counter: number;
    listType: ListType;
    show: boolean;
  }[] = [
    {
      name: 'Reports',
      path: path,
      counter: counters.reports,
      listType: 'reports',
      show: true,
    },
    {
      name: 'Drafts',
      path: `${path}/drafts`,
      counter: counters.drafts,
      listType: 'drafts',
      show: showDrafts,
    },
    {
      name: 'Archived',
      path: path,
      counter: counters.archived,
      listType: 'archived',
      show: true,
    },
  ];

  return (
    <div className="AnalysisTable__navbar">
      <NavbarMenu breakpoint={1050} className="AnalysisTable__navbar__list">
        {items.map((item) =>
          item.show ? (
            <NavbarMenuItem
              key={item.listType}
              className="AnalysisTable__navbar__item"
            >
              <NavBarLink
                to={item.path}
                className={`AnalysisTable__navbar__link${
                  listType === item.listType ? ' active' : ''
                }`}
                onClick={handleClick(item.listType)}
              >
                {item.name}{' '}
                <Caption
                  $color={tombac.color('neutral', 700)}
                  $paddingLeft="4px"
                >
                  ({item.counter})
                </Caption>
              </NavBarLink>
            </NavbarMenuItem>
          ) : null,
        )}
      </NavbarMenu>
    </div>
  );
};
